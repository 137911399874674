<template>
  <a-tag :color="attributes.color">
    {{ attributes.text }}
    <slot v-if="stateId == undefined || stateId == ''"> </slot>
  </a-tag>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Tags',
  props: {
    stateId: {
      type: [String, Number],
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const id_map = {
      1: {
        name: 'CREADO',
        color: 'processing'
      },
      2: {
        name: 'EN CORREDORA',
        color: 'warning'
      },
      3: {
        name: 'EN ASEGURADORA',
        color: 'warning'
      },
      4: {
        name: 'EJECUTADO',
        color: 'success'
      },
      5: {
        name: 'CANCELADO',
        color: 'error'
      }
    };

    // * Props
    const attributes = computed(() => {
      // Busco si el se agrego la propiedad state-id, de ser asi busco el objeto
      const data = id_map[props.stateId];

      let color = '';
      let text = '';

      if (data == undefined) {
        color = props.color == '' ? 'default' : props.color;
      } else {
        color = data.color;
        text = data.name;
      }

      return {
        text,
        color
      };
    });

    return {
      attributes
    };
  }
};
</script>
